export const handleWordSplit = (first?: string | null, second?: string | null) => {
  if (!first && !second) {
    return '';
  }
  if (!first) {
    return second ?? '';
  }
  if (!second) {
    return first ?? '';
  }
  return `${first} / ${second || ''}`;
};
