import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import EmptyState from 'src/components/empty-state/empty-state';
import Panel from 'src/components/panel/panel';
import useColumns from 'src/components/rankings/utils/useRankingRunColumns';
import { ControlProps } from 'src/components/table-controls/table-controls';
import { TD_GetRankList_td_rankList } from 'src/graphql-types/TD_GetRankList';

import { RunGroupQueryProps, UpdateFilterProps } from './ranking-run';
import RankingRunFilters from './ranking-run-filters';
import * as styles from './ranking-run-panel.module.less';

interface RankingRunPanelProps {
  rankList: TD_GetRankList_td_rankList;
  controlProps: ControlProps;
  loading: boolean;
  filters: RunGroupQueryProps;
  setFilters: (filters: Partial<UpdateFilterProps>) => void;
}

const RankingRunPanel: React.FC<RankingRunPanelProps> = ({ rankList, controlProps, loading, filters, setFilters }) => {
  const { t } = useTranslation();

  const listItems = useMemo(() => rankList?.rankingItems?.items ?? [], [rankList]);

  const cols: DataCols<any> = useColumns({
    rankList,
    styles,
  });

  const handleSetFilter = useCallback(
    (filterUpdate) => {
      setFilters(filterUpdate);
    },
    [setFilters],
  );

  return (
    <Panel extendedPadding>
      <RankingRunFilters filters={filters} setFilter={handleSetFilter} />
      {!loading && !listItems ? (
        <EmptyState title={t('no rankings yet')} icon="xl-rankings" />
      ) : (
        <AdminTable
          data={listItems}
          columns={cols}
          controls={controlProps}
          columnSelectorId={'ranking-run'}
          filters={filters}
        />
      )}
    </Panel>
  );
};

export default RankingRunPanel;
