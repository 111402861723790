import React, { useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Icon from 'src/components/icon/icon';
import { MovePlayerDialog } from 'src/components/move-player-dialog/move-player-dialog';
import { PlayerMoveRestrictionsDialog } from 'src/components/player-move-restrictions-dialog/player-move-restrictions-dialog';
import { useMoveRankListItem, useRemoveRankListItem } from 'src/components/rankings-run/rankings-run.api';
import { Body } from 'src/components/typography/typography';
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { SpacingProps } from 'src/hooks/spacing';
import { useOrgLevel } from 'src/utils/auth';

import { useRankListWildcardItems } from './rankings-add-player-panel.api';
import * as styles from './rankings-add-player-panel.module.less';

interface RankingsAddPlayerPanelProps {
  isPublished: boolean;
  disabled: boolean;
  refetch: any;
  listType?: ListTypeEnum;
}

function showMoveContainer(listType: ListTypeEnum | undefined) {
  if (!listType) return true;
  const validListTypes: ListTypeEnum[] = [ListTypeEnum.QUOTA];
  return validListTypes.find((type) => type === listType);
}

export const RankingsAddPlayerPanel = ({ isPublished, disabled, refetch, listType }: RankingsAddPlayerPanelProps) => {
  const { t } = useTranslation();
  const { rankingId } = useParams();
  const { isNational, isSection } = useOrgLevel();
  const [state, setState] = useState<'initial' | 'active'>('initial');
  const [restrictionsDialog, setRestrictionsDialog] = useState(false);
  const [movePlayerDialog, setMovePlayerDialog] = useState(false);

  const {
    data,
    loading: loadingRankListItems,
    wildcardLimit,
    currentWildcardAmount,
  } = useRankListWildcardItems(rankingId);

  const [moveRankListItem, { loading: movingRankListItem }] = useMoveRankListItem({
    listId: rankingId,
  });

  const [removeRankListItem, { loading: removingRankListItem }] = useRemoveRankListItem({
    listId: rankingId,
  });

  async function handleRemoveItem(itemId: string) {
    const itemToBeRemoved = data?.ranklist.rankingItemsPaginated?.items.find((item) => item?.id === itemId);
    if (itemToBeRemoved?.originalRank) {
      await moveRankListItem({
        variables: {
          listId: rankingId,
          newRank: itemToBeRemoved.originalRank,
          itemObjectId: itemId,
        },
      });
    } else {
      await removeRankListItem({ variables: { itemObjectId: itemId, listId: rankingId } });
    }
    refetch?.();
  }

  const rankListItem = useMemo(() => {
    return [...(data?.ranklist.rankingItemsPaginated?.items || [])].sort(
      (a, b) => (a.wildcardIndex || 0) - (b.wildcardIndex || 1),
    );
  }, [data]);

  function getMovePlayerButtonVisibility() {
    if (isPublished) return false;

    switch (listType) {
      case ListTypeEnum.QUOTA:
        return isNational || isSection;
      default:
        return isNational;
    }
  }

  useEffect(() => {
    if (currentWildcardAmount && currentWildcardAmount > 0) {
      setState('active');
    } else {
      setState('initial');
    }
  }, [currentWildcardAmount]);

  if (loadingRankListItems) return null;

  return (
    <>
      <Grid container justifyContent="space-between" className={styles.container}>
        {showMoveContainer(listType) && (
          <Grid container item direction="column" xs>
            <Grid container alignItems="center">
              <Body size="xl" bold>
                {t('player moves made', {
                  current: currentWildcardAmount || 0,
                  total: wildcardLimit || 2,
                })}
              </Body>
              {isNational && (
                <Icon name="md-annotation" className={styles.annotation} onClick={() => setRestrictionsDialog(true)} />
              )}
            </Grid>
            {state === 'initial' && (
              <Body size="xl" light spacing={{ margins: { sm: 'top' } }}>
                {t('player moves will appear')}
              </Body>
            )}
            <CustomGrid container>
              {state === 'active' &&
                rankListItem.map((item, index) => (
                  <RankingsAddPlayerPanelItem
                    key={item.rank || index}
                    playerName={item.participants?.[0]?.name || ''}
                    newRank={item.rank}
                    isPublished={isPublished}
                    spacing={{
                      margins: { xxs: index === 0 ? 'right' : 'left' },
                    }}
                    onDelete={() => handleRemoveItem(item.id)}
                    loading={removingRankListItem || movingRankListItem}
                    listType={listType}
                  />
                ))}
            </CustomGrid>
          </Grid>
        )}
        {getMovePlayerButtonVisibility() && (
          <CustomGrid container item xs justify="flex-end">
            <Button
              level="secondary"
              onClick={() => setMovePlayerDialog(true)}
              disabled={disabled || wildcardLimit === currentWildcardAmount}
            >
              {t('add move player')}
            </Button>
          </CustomGrid>
        )}
      </Grid>
      <CustomDialog
        title={t('player moves')}
        titleStyle={styles.dialogTitle}
        open={restrictionsDialog}
        hideX
        content={<PlayerMoveRestrictionsDialog onClose={() => setRestrictionsDialog(false)} />}
        onClose={() => setRestrictionsDialog(false)}
      />
      <CustomDialog
        title={t('add move player')}
        titleStyle={styles.dialogTitle}
        open={movePlayerDialog}
        hideX
        content={
          <MovePlayerDialog onClose={() => setMovePlayerDialog(false)} refetch={refetch} omitSectionAndDistrict />
        }
        onClose={() => setRestrictionsDialog(false)}
      />
    </>
  );
};

interface RankingsAddPlayerPanelItemProps extends SpacingProps {
  listType: ListTypeEnum | undefined;
  isPublished: boolean;
  playerName: string;
  newRank: number;
  onDelete: () => void;
  loading?: boolean;
}

const RankingsAddPlayerPanelItem = ({
  isPublished,
  spacing,
  playerName,
  newRank,
  onDelete,
  loading,
  listType,
}: RankingsAddPlayerPanelItemProps) => {
  const { t } = useTranslation();
  const { isNational, isSection } = useOrgLevel();

  function getRemoveIconVisibility() {
    if (isPublished) return false;

    switch (listType) {
      case ListTypeEnum.QUOTA:
        return isNational || isSection;
      default:
        return isNational;
    }
  }

  const [first, last] = playerName?.split(' ') || [];
  const name = `${last?.toUpperCase() || ''}, ${first}`;

  return (
    <CustomGrid
      container
      className={styles.panelItem}
      justify="center"
      alignItems="center"
      spacing={spacing}
      loading={loading}
    >
      <Grid container item xs direction="column">
        <Body size="md">{name}</Body>
        <Body size="md" light>
          {t('added to rank', { rank: newRank })}
        </Body>
      </Grid>
      {getRemoveIconVisibility() && (
        <CustomGrid item spacing={{ margins: { md: 'left' } }} className={styles.deleteIcon}>
          <Icon name="sm-delete" onClick={onDelete} />
        </CustomGrid>
      )}
    </CustomGrid>
  );
};
