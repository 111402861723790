import React, { CSSProperties } from 'react';

import VTDraggableRow from 'src/components/draggable-row/draggable-row';
import { RowConfigInterface } from 'src/components/virtualized-table/typedefs';
import { ColumnData, RowData } from 'src/components/virtualized-table/virtualized-table';

interface TableRowPreviewProps<T extends RowData> {
  columns: ColumnData<T>[];
  headerCells: HTMLCollection;
  itemWrapper: any;
  rowConfig?: RowConfigInterface;
  style?: CSSProperties;
  virtualListDiv: HTMLDivElement;
}

const TableRowPreview = <T extends RowData>({
  columns,
  headerCells,
  itemWrapper,
  rowConfig,
  style,
  virtualListDiv,
}: TableRowPreviewProps<T>) => {
  const item = itemWrapper.item;

  return rowConfig?.draggableRow ? (
    <div style={{ boxShadow: '0 0 3px 1px #c8c8c8', ...style }}>
      <VTDraggableRow
        columns={columns}
        customClassName={item.className}
        headerCells={headerCells}
        index={itemWrapper.startIndex}
        rowItem={item}
        virtualListDiv={virtualListDiv}
      />
    </div>
  ) : null;
};

export default TableRowPreview;
