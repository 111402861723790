import React from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TableRow } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import cx from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { TD } from 'src/components/table/table';

import * as styles from './drag-and-drop-table.module.less';

export const Row = ({ row, index, moveRow, onDrop, onClick, disabled, hideDragRef }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: 'row',
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = (item as any).index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (dropRef.current as any).getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      (item as any).index = hoverIndex;
    },
    drop(item, monitor) {
      onDrop(item, monitor, row);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'row', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <TableRow
      ref={dropRef}
      style={{ opacity }}
      onClick={disabled ? undefined : onClick}
      className={cx({
        [styles.rowDisabled]: disabled,
        [styles.clickableRow]: onClick && !disabled,
      })}
    >
      <td ref={dragRef}>
        {!hideDragRef && <SvgIcon className={styles.dragHandleCell} component={DragIndicatorIcon} />}
      </td>
      {row.cells.map((cell) => {
        return <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>;
      })}
    </TableRow>
  );
};
