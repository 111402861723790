import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/spinner/spinner';
import { SearchPeople, SearchPeople_searchPeople_results } from 'src/graphql-types/SearchPeople';

type TableProps = {
  players: SearchPeople;
  loading: boolean;
  page: number;
  perPage: number;
  onChangeRows: (event) => void;
  setSelectedPlayer: (player) => void;
  onChangePage: (event, newPage) => void;
};

const RankingSelectPlayerTable = ({
  players,
  loading,
  page,
  perPage,
  onChangeRows,
  onChangePage,
  setSelectedPlayer,
}: TableProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<SearchPeople_searchPeople_results>();

  const handleClick = (event, row) => {
    setSelected(row);
    setSelectedPlayer(row);
  };

  useEffect(() => {
    //  Reset modal data on close
    setSelected(undefined);
  }, [players]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && players?.searchPeople?.results?.length === 0 && (
        <div style={{ textAlign: 'center' }}>{t('no players found')}</div>
      )}
      {!loading && players?.searchPeople?.results?.length > 0 && (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <strong>{t('name')}</strong>
                </TableCell>
                <TableCell>
                  <strong>{t('school')}</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players.searchPeople.results.map((row, index) => {
                const isItemSelected = row.personId === selected?.personId;
                const schoolName = row.groups.find((grp) => grp.year);

                return (
                  <TableRow key={index} onClick={(event) => handleClick(event, row)} hover>
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.standardGivenName} {row.standardFamilyName}
                    </TableCell>
                    <TableCell>{schoolName?.groupName}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  count={players.searchPeople.total}
                  page={page}
                  rowsPerPage={perPage}
                  onRowsPerPageChange={onChangeRows}
                  onPageChange={onChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default RankingSelectPlayerTable;
