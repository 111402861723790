import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Divider, Grid } from '@mui/material';
import cx from 'classnames';
import { print } from 'graphql';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown from 'src/components/dropdown/dropdown';
import FloatingNotification from 'src/components/floating-notification/floating-notification';
import AutomaticDownload from 'src/components/generate-report/automatic-download';
import { CSVValueTransform, ReportUploadState } from 'src/components/generate-report/generate-report';
import Panel from 'src/components/panel/panel';
import { TD_GET_RANK_LIST_DOWNLOAD_TEMPLATE } from 'src/components/rankings/queries';
import getFileName from 'src/components/rankings/utils/getFileName';
import getRankingPeriod from 'src/components/rankings/utils/getRankingPeriod';
import slashParticipantField from 'src/components/rankings/utils/slashParticipantField';
import { DownloadButton } from 'src/components/reports/download-button/download-report-button';
import Spinner from 'src/components/spinner/spinner';
import { Body, H3 } from 'src/components/typography/typography';
import { getEnvConfig } from 'src/config/config';
import { TD_GetRankList_td_rankList } from 'src/graphql-types/TD_GetRankList';
import { userIsGlobalAdmin } from 'src/utils/auth';
import useDateTime from 'src/utils/helper/useDateTime';

import { VisibilityStatusEnum } from './ranking-run';
import * as styles from './ranking-run-header.module.less';

interface RankingRunHeaderRankList extends TD_GetRankList_td_rankList {
  rankListName?: string | null;
  runLabel: string;
}

interface RankingRunHeaderProps {
  rankList: RankingRunHeaderRankList;
  handlePublishRun: (status: VisibilityStatusEnum) => void;
  handleArchiveRun: () => void;
  loading: boolean;
}

const RankingRunHeader: React.FC<RankingRunHeaderProps> = ({
  rankList,
  handlePublishRun,
  handleArchiveRun,
  loading,
}) => {
  const { t } = useTranslation();
  const isGlobalAdmin = userIsGlobalAdmin();
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none');
  const [downloadable, setDownloadable] = useState<boolean>(false);
  const [popupClosed, setPopupClosed] = useState(false);

  const { formatDate } = useDateTime(undefined, {
    format: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  });

  const { id, rankListName, runLabel, visible, dateRange, updatedAt } = rankList;

  const { startDate, endDate, updatedAtDate } = useMemo(() => {
    if (!dateRange || !updatedAt) {
      return { startDate: null, endDate: null, updatedAtDate: null };
    }
    const { start, end } = dateRange || {};
    return {
      startDate: new Date(start),
      endDate: new Date(end),
      updatedAtDate: formatDate(new Date(updatedAt)),
    };
  }, [dateRange, formatDate, updatedAt]);

  const [visibilityStatus, setVisibilityStatus] = useState<VisibilityStatusEnum>(VisibilityStatusEnum.HIDDEN);

  useEffect(() => {
    if (visible) {
      setVisibilityStatus(VisibilityStatusEnum.PUBLISHED);
    }
  }, [visible]);

  const visibilityOptions = [
    { value: VisibilityStatusEnum.HIDDEN, label: t('hidden') },
    { value: VisibilityStatusEnum.PUBLISHED, label: t('published') },
  ];

  const handleOnPublish = useCallback(
    async (status: VisibilityStatusEnum) => {
      setVisibilityStatus(status);
      await handlePublishRun(status);
    },
    [handlePublishRun],
  );

  const handleOnArchive = useCallback(async () => {
    await handleArchiveRun();
  }, [handleArchiveRun]);

  const handleOnReportUploadStateChange = useCallback((state: ReportUploadState) => {
    if (state === 'processing') {
      setPopupClosed(false);
    }
    setReportUploadState(state);
  }, []);

  return (
    <Panel extendedPadding>
      <Grid container>
        <Grid container item xs direction="column">
          <H3 spacing={{ margins: { sm: 'right' } }} noMargin>
            {runLabel}
          </H3>
          <Body size="md">{rankListName}</Body>
        </Grid>
        <CustomGrid container item xs justify="flex-end" alignItems="flex-start">
          <Body size="lg" light spacing={{ margins: { sm: ['top', 'right'] } }}>
            {t('publish status')}:
          </Body>
          {loading && (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          )}
          {!loading && (
            <div className={styles.dropdownWrapper}>
              <div
                className={cx(styles.dropdownDecoration, {
                  [styles.dropdownDecorationHidden]: visibilityStatus === VisibilityStatusEnum.HIDDEN,
                  [styles.dropdownDecorationPublished]: visibilityStatus === VisibilityStatusEnum.PUBLISHED,
                })}
              ></div>
              <Dropdown
                options={visibilityOptions as any}
                selected={visibilityStatus as any}
                onSelect={(option) => handleOnPublish(option.value as VisibilityStatusEnum)}
                spacing={{ margins: { xs: 'left' } }}
              />
            </div>
          )}
          <CustomGrid spacing={{ margins: { sm: 'left' } }} hide={!isGlobalAdmin || visible}>
            <Button level="secondary" onClick={() => handleOnArchive()}>
              {t('archive')}
            </Button>
          </CustomGrid>
          <CustomGrid spacing={{ margins: { sm: 'left' } }}>
            <AutomaticDownload
              reportUploadState={reportUploadState}
              onReportUploadStateChange={handleOnReportUploadStateChange}
              generateButtonTitle={<DownloadButton />}
              downloadable={downloadable}
              setDownloadable={setDownloadable}
              reportQuery={print(TD_GET_RANK_LIST_DOWNLOAD_TEMPLATE)}
              reportQueryEndpoint={getEnvConfig().MESH_GATEWAY_GQL_URL}
              paginator={{
                rootFieldPath: 'td_rankList.rankingItems.items',
                pageSize: 1000,
              }}
              reportQueryVariables={{ id }}
              filename={getFileName(rankListName || '')}
              csvFormatOptions={{ disableUnwind: true }}
              csvTransforms={[
                { key: 'id', label: t('id') },
                { label: t('usta id'), ...slashParticipantField('itemId') },
                { label: t('name'), ...slashParticipantField('name') },
                { label: t('city'), ...slashParticipantField('city') },
                { ...slashParticipantField('state'), label: t('state') },
                { ...slashParticipantField('section'), label: t('section') },
                { ...slashParticipantField('district'), label: t('district') },
                {
                  label: t('birth date'),
                  key: 'participants',
                  transforms: [
                    {
                      operation: CSVValueTransform.ARRAY_FIELD_SELECT,
                      parameters: [{ key: 'fieldPath', value: 'birthDate' }],
                    },
                    {
                      operation: CSVValueTransform.FORMAT_UTC_DATE,
                      parameters: [{ key: 'dateFormat', value: 'MM-DD-YYYY' }],
                    },
                    {
                      operation: CSVValueTransform.ARRAY_JOIN,
                      parameters: [{ key: 'delimiter', value: ' / ' }],
                    },
                  ],
                },
                { key: 'sectionRank', label: t('section position') },
                { key: 'districtRank', label: t('district position') },
                { key: 'rank', label: t('national rank') },
                { key: 'points.singles', label: t('singles points') },
                { key: 'points.doubles', label: t('doubles points') },
                { key: 'points.bonus', label: t('bonus points') },
                { key: 'points.total', label: t('total points') },
              ]}
            />
            {reportUploadState === 'processing' && !popupClosed && (
              <FloatingNotification
                message={t('preparing download')}
                onClose={() => setPopupClosed(true)}
                variant="download"
                hideCloseButton
              />
            )}
            {reportUploadState === 'downloadable' && !popupClosed && (
              <FloatingNotification
                icon={{ name: 'md-tick-circle', className: styles.tick }}
                message={t('report downloaded')}
                variant="downloaded"
                onClose={() => setPopupClosed(true)}
              />
            )}
          </CustomGrid>
        </CustomGrid>
      </Grid>
      <Divider className={styles.divider} />
      <CustomGrid container xs>
        <CustomGrid spacing={{ margins: { lg: 'right' } }}>
          <Body size="md" bold>
            {t('ranking period')}
          </Body>
          <Body size="md">
            {getRankingPeriod({
              start: startDate,
              end: endDate,
              t,
            })}
          </Body>
        </CustomGrid>
        <CustomGrid>
          <Body size="md" bold>
            {t('last updated')}
          </Body>
          <Body size="md">{t('payment date', { date: updatedAtDate })}</Body>
        </CustomGrid>
      </CustomGrid>
    </Panel>
  );
};

export default RankingRunHeader;
