import React from 'react';

import { Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import * as styles from 'src/components/rankings-run/rankings-run.module.less';
import { Body } from 'src/components/typography/typography';
import {
  Ranklist_ranklist_rankingItemsPaginated_items_participants as Participant,
  Ranklist_ranklist_rankingItemsPaginated_items as RanklistItem,
} from 'src/graphql-types/Ranklist';

interface MoveDialogProps {
  ranklistItem: RanklistItem;
  disabledConditions: boolean[];
  setSelectedPlayer: (player: (Participant & { rank: number }) | null) => void;
}

export function MoveDialog({ ranklistItem, disabledConditions, setSelectedPlayer }: MoveDialogProps) {
  const { t } = useTranslation();

  return (
    <Tooltip
      disableInteractive
      placement="top"
      title={
        <Grid container>
          <Body size="xs" color={Body.color.WHITE}>
            {t('delete move first')}
          </Body>
        </Grid>
      }
      classes={{ tooltip: styles.tooltip }}
      disableHoverListener={!disabledConditions.some(Boolean)}
    >
      <div>
        <Button
          size="sm"
          level="tertiary"
          onClick={() => {
            if (disabledConditions.some(Boolean)) {
              return undefined;
            }
            const [participant] = ranklistItem?.participants || [];
            setSelectedPlayer({ ...participant, rank: ranklistItem.rank });
          }}
          mockDisabledStyle={disabledConditions.some(Boolean)}
        >
          {t('move')}
        </Button>
      </div>
    </Tooltip>
  );
}
