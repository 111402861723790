/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import cx from 'classnames';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { print } from 'graphql';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable from 'src/components/admin-table/admin-table';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import { DragAndDropTable } from 'src/components/drag-and-drop-table/drag-and-drop-table';
import Dropdown from 'src/components/dropdown/dropdown';
import FloatingNotification from 'src/components/floating-notification/floating-notification';
import AutomaticDownload from 'src/components/generate-report/automatic-download';
import {
  CSVValueTransform,
  OnReportUploadStateChange,
  ReportUploadState,
} from 'src/components/generate-report/generate-report';
import TextInput from 'src/components/input/input';
import { useDebounce } from 'src/components/membership/membership';
import { MovePositionDialog } from 'src/components/move-position-dialog/move-position-dialog';
import Panel from 'src/components/panel/panel';
import { RankingsAddPlayerPanel } from 'src/components/rankings-add-player-panel/rankings-add-player-panel';
import { useRankListWildcardItems } from 'src/components/rankings-add-player-panel/rankings-add-player-panel.api';
import Spinner from 'src/components/spinner/spinner';
import { transformSortDirection, useControlledQuery } from 'src/components/table-controls/table-controls';
import { Body } from 'src/components/typography/typography';
import { getRanklistJobsQueryParams, getRanklistQueryParams } from 'src/components/usta-rankings/helpers';
import {
  GET_RANK_LIST,
  GET_RANKLIST_RUN_GROUPS,
  UPDATE_RANKLIST_VISIBILITY,
} from 'src/components/usta-rankings/usta-ranking-queries';
import { getRankingPeriod } from 'src/components/usta-ranklist/usta-ranklist.utils';
import { getEnvConfig } from 'src/config/config';
import {
  ListTypeEnum,
  PlayerTypeEnum,
  RankListRunGroupSortFieldsEnum,
  SortDirectionEnum,
} from 'src/graphql-types/globalRankingTypes';
import {
  Ranklist_ranklist_rankingItemsPaginated_items_participants as Participant,
  Ranklist,
  Ranklist_ranklist,
  Ranklist_ranklist_rankingItemsPaginated_items as RanklistItem,
} from 'src/graphql-types/Ranklist';
import { SoftArchiveRankLists, SoftArchiveRankListsVariables } from 'src/graphql-types/SoftArchiveRankLists';
import { useOrgLevel, userIsGlobalAdmin } from 'src/utils/auth';
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name';
import ROUTES from 'src/utils/routes';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import { ARCHIVE_RANK_LISTS, GET_RANK_LIST_DOWNLOAD_TEMPLATE } from './rankings-run-queries';
import { useMoveRankListItem } from './rankings-run.api';
import { useColumns } from './rankings-run.columns';
import { useDraggableColumns } from './rankings-run.draggable-columns';
import * as styles from './rankings-run.module.less';
import {
  getDistrictEnumOptions,
  getDivisionType,
  getFileName,
  getLocalityFilters,
  getSearchFilter,
  getSectionEnumOptions,
  handleRowClick,
  isTeamDoubles,
  slashParticipantField,
} from './rankings-run.utils';

export interface ColsVisibility {
  key: string;
  checked: boolean;
  index: number;
  hidden?: boolean;
  disabled?: boolean;
}

enum VisibilityStatusEnum {
  IDLE = 'IDLE',
  HIDDEN = 'HIDDEN',
  PUBLISHED = 'PUBLISHED',
}

const TABLE_ID = 'rankings_run';

function displayPlayerPanel(listType: ListTypeEnum | undefined) {
  const listsWithPlayerPanel: ListTypeEnum[] = [
    ListTypeEnum.QUOTA,
    ListTypeEnum.DIRECT_ACCEPTANCE,
    ListTypeEnum.L2_QUALIFIER,
  ];

  return listsWithPlayerPanel.some((list) => list === listType);
}

interface IsDragRefHiddenProps {
  ranklist: Ranklist_ranklist | undefined;
  loading: boolean;
  isNationalAdmin: boolean;
}

function isDragRefHidden({ ranklist, loading, isNationalAdmin }: IsDragRefHiddenProps) {
  return !!(ranklist?.visible || loading || !isNationalAdmin);
}

const USTARankingsRun = ({ rankingId }) => {
  const { t } = useTranslation();
  const isGlobalAdmin = userIsGlobalAdmin();
  const { isNational, isSection } = useOrgLevel();
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<(Participant & { rank: number }) | null>(null);
  const [listUpdated, setListUpdated] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [query, setQuery] = useQueryParams({
    latestListMadeVisible: StringParam,
    ranklistJobs: ArrayParam,
    search: StringParam,
    section: StringParam,
    district: StringParam,
  });

  const searchFilter = useDebounce(query.search, 500);

  const {
    data: ranklistData,
    loading: loadingRanklist,
    controlProps,
    refetch,
    networkStatus,
  } = useControlledQuery<Ranklist>(GET_RANK_LIST, {
    client: meshGatewayClient,
    getTotalItems: (d: Ranklist) => d.ranklist?.rankingItemsPaginated?.totalItems ?? 0,
    transformVariables: (optionsWithControls: any) => {
      const { sorts, limit, offset } = optionsWithControls ?? {};
      const itemFilter = {
        localityFilter: getLocalityFilters(query.section, query.district),
        ...getSearchFilter(searchFilter),
      };
      const queryVariables = {
        id: rankingId,
        itemPageArgs: { limit, skip: offset },
        itemFilter,
      };
      if (sorts) {
        queryVariables['sort'] = {
          field: sorts[0].property,
          direction: transformSortDirection(sorts[0].sortDirection),
        };
      }
      return queryVariables;
    },
    fetchPolicy: 'no-cache',
  });

  const ranklist = useMemo(() => {
    return {
      ...ranklistData?.ranklist,
      ...((ranklistData?.ranklist?.playerType === PlayerTypeEnum.WHEELCHAIR
        ? { divisionType: ranklistData?.ranklist?.playerLevel }
        : {}) as Ranklist_ranklist),
    };
  }, [ranklistData?.ranklist]);

  const getDisabledConditions = (ranklistItem: RanklistItem) => {
    return [
      currentWildcardAmount === wildcardLimit && !ranklistItem.wildcard,
      isVisibilityStatusPublished,
      !isNational && !isSection,
    ];
  };

  useEffect(() => {
    if (listUpdated) {
      const timer = setTimeout(() => setListUpdated(false), 4000); // 4 seconds

      return () => {
        clearTimeout(timer);
      };
    }
  }, [listUpdated]);

  interface UpdateFilterProps {
    search: string;
    district: string;
    section: string;
  }

  function updateFilters(props: Partial<UpdateFilterProps>) {
    setQuery(props);
  }

  const visibilityOptions = [
    { value: VisibilityStatusEnum.HIDDEN, label: t('hidden') },
    { value: VisibilityStatusEnum.PUBLISHED, label: t('published') },
  ];

  const [visibilityStatus, setVisibilityStatus] = useState<VisibilityStatusEnum>(VisibilityStatusEnum.IDLE);

  const isVisibilityStatusIdle = visibilityStatus === VisibilityStatusEnum.IDLE;
  const isVisibilityStatusHidden = visibilityStatus === VisibilityStatusEnum.HIDDEN;
  const isVisibilityStatusPublished = visibilityStatus === VisibilityStatusEnum.PUBLISHED;

  const {
    listType,
    familyCategory = null,
    playerType = null,
    ageRestriction = null,
    gender = null,
    genderModifier = null,
    matchFormat = null,
    matchFormatType = null,
    playerLevel = null,
    region = null,
    visible,
  } = ranklist ?? {};

  const { wildcardLimit, currentWildcardAmount } = useRankListWildcardItems(rankingId);

  const [updateRanklistVisibility, { loading: updatingRanklistVisibility }] = useMutation(UPDATE_RANKLIST_VISIBILITY, {
    client: meshGatewayClient,
    refetchQueries: [
      {
        query: GET_RANKLIST_RUN_GROUPS,
        variables: {
          ranklistRunGroupFilter: {},
          pageArgs: { limit: 10, skip: 0 },
          sort: {
            field: RankListRunGroupSortFieldsEnum.LATEST_LIST_CREATED_AT,
            direction: SortDirectionEnum.DESC,
          },
        },
      },
    ],
  });

  const [archiveRankLists, { loading: archivingRankLists }] = useMutation<
    SoftArchiveRankLists,
    SoftArchiveRankListsVariables
  >(ARCHIVE_RANK_LISTS, {
    client: meshGatewayClient,
  });

  // Set initial visibilityStatus after ranklist has loaded
  useEffect(() => {
    // Ranklist not published
    if (ranklist && !ranklist.visible) setVisibilityStatus(VisibilityStatusEnum.HIDDEN);
    // Ranklist published
    if (ranklist && ranklist.visible) setVisibilityStatus(VisibilityStatusEnum.PUBLISHED);
  }, [ranklist]);

  // Handle visibilityStatus change
  useEffect(() => {
    if (
      ranklist &&
      Object.keys(ranklist || {}).length !== 0 &&
      !isVisibilityStatusIdle &&
      ranklist.visible !== isVisibilityStatusPublished
    ) {
      const updateRanklistVisibilityFn = async () => {
        const response = await updateRanklistVisibility({
          variables: { id: rankingId, visible: isVisibilityStatusPublished },
        });
        if (isVisibilityStatusPublished && response) {
          setQuery({ latestListMadeVisible: response.data?.updateRankListVisibility?.updatedAt });
        }
      };
      (async () => {
        await updateRanklistVisibilityFn();
        refetch();
      })();
    }
  }, [visibilityStatus]);

  const queryParams = getRanklistQueryParams({
    listType: listType,
    playerType,
    ageRestriction,
    gender,
    genderModifier,
    matchFormat,
    matchFormatType,
    playerLevel,
    latestListMadeVisible: query.latestListMadeVisible,
    familyCategory,
    region,
    ...getDivisionType(playerType as PlayerTypeEnum, playerLevel),
  });
  const runLabel = `${t('run')}: ${t('member date', { date: dayjs(ranklist?.createdAt).local() })}`;

  const { start, end } = ranklist?.dateRange ?? {};
  const ranklistName = ranklist ? generateRanklistName(ranklist, t) : '';

  const [downloadable, setDownloadable] = useState<boolean>(false);
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none');
  const [popupClosed, setPopupClosed] = useState(false);

  const onReportUploadStateChange = useCallback<OnReportUploadStateChange>(
    (state) => {
      if (state === 'processing') {
        setPopupClosed(false);
      }
      setReportUploadState(state);
    },
    [setReportUploadState, setPopupClosed],
  );

  const cols = useColumns({
    ranklist,
    setSelectedPlayer,
    getDisabledConditions,
    isVisibilityStatusPublished,
  });

  const draggableCols = useDraggableColumns({
    ranklist,
    setSelectedPlayer,
    getDisabledConditions,
    isVisibilityStatusPublished,
  });

  const hiddenColumns = draggableCols.filter((col) => (col as any).show === false).map((col) => col.accessor);

  const ranklistJobsQueryParams = getRanklistJobsQueryParams(query.ranklistJobs);

  const districtOptions = useMemo(() => {
    const sortedDistrictOptions = getDistrictEnumOptions(query.section as any, t)?.sort((a, z) =>
      a?.label?.localeCompare(z.label),
    );
    return [{ value: '', label: t('any district') }, ...sortedDistrictOptions];
  }, [query.section, t]);

  async function handleArchiveRanklist() {
    await archiveRankLists({ variables: { ids: [ranklist?.id] } });
    navigate(`${ROUTES.RANKINGS}/group${queryParams}&${ranklistJobsQueryParams}`);
  }

  const [moveRankListItem, { loading: movingRankListItem }] = useMoveRankListItem({
    listId: rankingId,
  });

  async function handleRowDrop(_item, _, row: any) {
    const currentPosition = currentPage * 10 + row.index;
    const newPosition = currentPage * 10 + row.index + 1;

    await moveRankListItem({
      variables: {
        listId: rankingId,
        newRank: +newPosition,
        itemObjectId: row?.id as string,
      },
    });

    await refetch();
    setListUpdated(true);
  }

  return (
    <>
      <div>
        <Breadcrumbs
          paths={[
            {
              name: t('rankings'),
              to: `${ROUTES.RANKINGS}?${ranklistJobsQueryParams}`,
            },
            {
              name: ranklistName,
              to: `${ROUTES.RANKINGS}/group${queryParams}&${ranklistJobsQueryParams}`,
            },
            {
              name: runLabel,
              active: true,
            },
          ]}
        />
        <Panel>
          {loadingRanklist ? (
            <Spinner />
          ) : (
            <>
              <Grid container>
                <Grid container item xs alignItems="center">
                  <Grid container direction="column">
                    <h3 className={styles.heading}>{runLabel}</h3>
                    <Body size="md">{ranklistName}</Body>
                  </Grid>
                </Grid>
                <CustomGrid container item xs justify="flex-end" alignItems="flex-start">
                  <Body size="lg" light spacing={{ margins: { sm: ['top', 'right'] } }}>
                    {t('publish status')}:
                  </Body>
                  {updatingRanklistVisibility && (
                    <div className={styles.spinnerContainer}>
                      <Spinner />
                    </div>
                  )}
                  {!updatingRanklistVisibility && (
                    <div className={styles.dropdownWrapper}>
                      <div
                        className={cx(styles.dropdownDecoration, {
                          [styles.dropdownDecorationHidden]: isVisibilityStatusHidden,
                          [styles.dropdownDecorationPublished]: isVisibilityStatusPublished,
                        })}
                      ></div>
                      <Dropdown
                        options={visibilityOptions as any}
                        selected={visibilityStatus as any}
                        onSelect={(option) => setVisibilityStatus(VisibilityStatusEnum[option.value])}
                        spacing={{ margins: { xs: 'left' } }}
                        disabled={!ranklist || Boolean(query.ranklistJobs) || isVisibilityStatusIdle || !isNational}
                      />
                    </div>
                  )}
                  <CustomGrid
                    spacing={{ margins: { sm: 'left' } }}
                    hide={!isGlobalAdmin || isVisibilityStatusPublished}
                  >
                    <Button level="secondary" onClick={() => setArchiveDialog(true)}>
                      {t('archive')}
                    </Button>
                  </CustomGrid>
                  <CustomGrid spacing={{ margins: { sm: 'left' } }}>
                    <AutomaticDownload
                      reportUploadState={reportUploadState}
                      onReportUploadStateChange={onReportUploadStateChange}
                      generateButtonTitle={t('download csv')}
                      downloadable={downloadable}
                      setDownloadable={setDownloadable}
                      reportQuery={print(GET_RANK_LIST_DOWNLOAD_TEMPLATE)}
                      reportQueryEndpoint={getEnvConfig().MESH_GATEWAY_GQL_URL}
                      paginator={{
                        rootFieldPath: 'ranklist.rankingItemsPaginated.items',
                        pageSize: 1000,
                      }}
                      reportQueryVariables={{
                        id: rankingId,
                      }}
                      filename={getFileName(ranklistName || '')}
                      csvFormatOptions={{ disableUnwind: true }}
                      csvTransforms={[
                        { key: 'id', label: t('id') },
                        {
                          label: t('usta id'),
                          ...slashParticipantField('itemId'),
                        },
                        { label: t('name'), ...slashParticipantField('name') },
                        { label: t('city'), ...slashParticipantField('city') },
                        {
                          ...slashParticipantField('state'),
                          label: t('state'),
                        },
                        {
                          ...slashParticipantField('section'),
                          label: t('section'),
                        },
                        {
                          ...slashParticipantField('district'),
                          label: t('district'),
                        },
                        {
                          label: t('birth date'),
                          key: 'participants',
                          transforms: [
                            {
                              operation: CSVValueTransform.ARRAY_FIELD_SELECT,
                              parameters: [{ key: 'fieldPath', value: 'birthDate' }],
                            },
                            {
                              operation: CSVValueTransform.FORMAT_UTC_DATE,
                              parameters: [{ key: 'dateFormat', value: 'MM-DD-YYYY' }],
                            },
                            {
                              operation: CSVValueTransform.ARRAY_JOIN,
                              parameters: [{ key: 'delimiter', value: ' / ' }],
                            },
                          ],
                        },
                        { key: 'sectionRank', label: t('section position') },
                        { key: 'districtRank', label: t('district position') },
                        { key: 'rank', label: t('national rank') },
                        { key: 'points.singles', label: t('singles points') },
                        { key: 'points.doubles', label: t('doubles points') },
                        { key: 'points.bonus', label: t('bonus points') },
                        { key: 'points.total', label: t('total points') },
                      ]}
                    />
                    {reportUploadState === 'processing' && !popupClosed && (
                      <FloatingNotification
                        message={t('preparing download')}
                        onClose={() => setPopupClosed(true)}
                        variant="download"
                        hideCloseButton
                      />
                    )}
                    {reportUploadState === 'downloadable' && !popupClosed && (
                      <FloatingNotification
                        icon={{
                          name: 'md-tick-circle',
                          className: styles.tick,
                        }}
                        message={t('report downloaded')}
                        variant="downloaded"
                        onClose={() => setPopupClosed(true)}
                      />
                    )}
                  </CustomGrid>
                </CustomGrid>
              </Grid>
              <div className={styles.divider} />
              <Grid container>
                {ranklist?.listType !== ListTypeEnum.L2_QUALIFIER && (
                  <CustomGrid spacing={{ margins: { lg: 'right' } }}>
                    <Body size="md" bold>
                      {t('ranking period')}
                    </Body>
                    <Body size="md">{getRankingPeriod({ start, end, t })}</Body>
                  </CustomGrid>
                )}

                <CustomGrid
                  spacing={
                    ranklist?.listType === ListTypeEnum.L2_QUALIFIER ? undefined : { margins: { lg: 'horizontal' } }
                  }
                >
                  <Body size="md" bold>
                    {t('last updated')}
                  </Body>
                  <Body size="md">{t('payment date', { date: dayjs(ranklist?.updatedAt).local() })}</Body>
                </CustomGrid>
              </Grid>
            </>
          )}
        </Panel>
        <Panel extendedPadding>
          {displayPlayerPanel(listType) && (
            <RankingsAddPlayerPanel
              isPublished={isVisibilityStatusPublished}
              disabled={loadingRanklist}
              refetch={() => {
                refetch();
                setListUpdated(true);
              }}
              listType={listType}
            />
          )}
          <CustomGrid container justify-content="space-between" spacing={{ margins: { md: 'bottom' } }}>
            <Grid container item xs={8}>
              <CustomGrid item spacing={{ margins: { sm: 'right' } }}>
                <div className={styles.inputContainer}>
                  <TextInput
                    fluid
                    placeholder={t('search by name or uaid')}
                    outlined
                    disableUnderline
                    value={query.search}
                    onChange={(e) => updateFilters({ search: e.target.value })}
                  />
                </div>
              </CustomGrid>
              <CustomGrid item spacing={{ margins: { sm: 'right' } }}>
                <Dropdown
                  selected={query.section}
                  options={getSectionEnumOptions(t)}
                  onSelect={(o) => {
                    if (!o.value) updateFilters({ district: '', section: o.value });
                    if (o.value) updateFilters({ section: o.value });
                  }}
                />
              </CustomGrid>
              <CustomGrid item spacing={{ margins: { sm: 'right' } }}>
                <Dropdown
                  selected={query.district}
                  options={districtOptions}
                  onSelect={(o) => updateFilters({ district: o.value })}
                  disabled={!query.section}
                />
              </CustomGrid>
            </Grid>
          </CustomGrid>
          {[ListTypeEnum.DIRECT_ACCEPTANCE, ListTypeEnum.L2_QUALIFIER].includes(listType) ? (
            <DragAndDropTable
              data={ranklist?.rankingItemsPaginated?.items ?? []}
              columns={draggableCols}
              onDrop={handleRowDrop}
              loading={loadingRanklist || movingRankListItem || networkStatus === 4}
              controls={controlProps}
              handlePageChange={(page) => setCurrentPage(page)}
              hiddenColumns={hiddenColumns as any}
              hideDragRef={isDragRefHidden({
                ranklist,
                loading: loadingRanklist || movingRankListItem,
                isNationalAdmin: isNational,
              })}
              rowsPerPageExpanded={true}
              columnSelectorId={TABLE_ID}
            />
          ) : (
            <AdminTable
              data={ranklist?.rankingItemsPaginated?.items ?? []}
              columns={cols}
              loading={loadingRanklist}
              controls={controlProps}
              filters={query}
              onRowClick={
                isTeamDoubles(matchFormat, matchFormatType) || !visible ? undefined : (e) => handleRowClick(e, ranklist)
              }
              rowsPerPageExpanded={true}
              columnSelectorId={TABLE_ID}
            />
          )}
        </Panel>
      </div>
      <CustomDialog
        title={t('archive list')}
        open={archiveDialog}
        hideX
        overridePosition={{ width: '40%', margin: '0 auto' }}
        content={<Body size="md">{t('archive list question')}</Body>}
        onClose={() => setArchiveDialog(false)}
        actions={
          <CustomGrid container justify="flex-end" className={styles.archiveActionContainer}>
            <Button
              level="tertiary"
              spacing={{ margins: { sm: 'right' } }}
              onClick={() => setArchiveDialog(false)}
              disabled={archivingRankLists}
            >
              {t('cancel')}
            </Button>
            <Button onClick={handleArchiveRanklist} loading={archivingRankLists}>
              {t('confirm')}
            </Button>
          </CustomGrid>
        }
      />
      <CustomDialog
        title={t('move to position')}
        open={Boolean(selectedPlayer)}
        hideX
        content={
          <MovePositionDialog
            onClose={() => setSelectedPlayer(null)}
            player={selectedPlayer}
            refetch={() => {
              refetch();
              setListUpdated(true);
            }}
          />
        }
        onClose={() => setSelectedPlayer(null)}
      />
      {listUpdated && (
        <FloatingNotification
          message={t('list updated')}
          onClose={() => setListUpdated(false)}
          variant="success"
          size="sm"
        />
      )}
    </>
  );
};

export default USTARankingsRun;
