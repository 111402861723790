import React, { FormEvent, useState } from 'react';

import { Grid } from '@mui/material';
import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import TextInput from 'src/components/input/input';
import {
  getEnumKeyFromValue,
  MappedDistrictEnum,
  MappedSectionEnum,
} from 'src/components/move-player-dialog/move-player-dialog';
import {
  useRankListItem,
  useRankListWildcardItems,
} from 'src/components/rankings-add-player-panel/rankings-add-player-panel.api';
import { useAddRankListItem, useMoveRankListItem, useRanklist } from 'src/components/rankings-run/rankings-run.api';
import Spinner from 'src/components/spinner/spinner';
import { Body } from 'src/components/typography/typography';
import { Ranklist_ranklist_rankingItemsPaginated_items_participants } from 'src/graphql-types/Ranklist';

interface MovePlayerDialogProps {
  onClose: () => void;
  player: (Ranklist_ranklist_rankingItemsPaginated_items_participants & { rank: number }) | null;
  refetch: any;
}

export const MovePositionDialog = ({ player, onClose, refetch }: MovePlayerDialogProps) => {
  const { t } = useTranslation();
  const { rankingId } = useParams();
  const [newPosition, setNewPosition] = useState('');
  const [error, setError] = useState('');
  const [samePositionError, setSamePositionError] = useState('');

  const { data: rankListItemsData } = useRankListWildcardItems(rankingId);
  const { ranklistItem } = useRankListItem(rankingId, player?.itemId as string);
  const [addRankListItem, { loading: addingRankListItem }] = useAddRankListItem({
    listId: rankingId,
  });
  const [moveRankListItem, { loading: movingRankListItem }] = useMoveRankListItem({
    listId: rankingId,
  });

  const {
    loading: loadingRanklist,
    controlProps,
    refetch: refetchRanklist,
  } = useRanklist({
    id: rankingId,
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (+newPosition === player?.rank) {
      setSamePositionError(`The player is already in position ${newPosition}`);
      return;
    }

    setSamePositionError('');

    if (
      +newPosition < 1 ||
      ((controlProps?.totalItems as number) > 0 && +newPosition > (controlProps?.totalItems || 0) + 1)
    ) {
      setError(`Assign a position between 1 - ${controlProps?.totalItems}`);
      return;
    }

    if (!newPosition.match(/^[0-9]+$/)) {
      setError('Position must be a number');
      return;
    }

    if (!newPosition) {
      setError('Position field is required');
      return;
    }

    setError('');

    const itemAlreadyExists = rankListItemsData?.ranklist?.rankingItemsPaginated?.items?.find(
      (p) => p?.participants?.[0]?.itemId === player?.itemId,
    );

    try {
      if (itemAlreadyExists || (ranklistItem && ranklistItem?.length > 0)) {
        await moveRankListItem({
          variables: {
            listId: rankingId,
            newRank: +newPosition,
            itemObjectId: itemAlreadyExists?.id || (ranklistItem?.[0]?.id as string),
          },
        });
      } else {
        await addRankListItem({
          variables: {
            listId: rankingId,
            input: {
              participants: [
                {
                  itemId: player?.itemId || '',
                  name: player?.name || '',
                  birthDate: String(player?.birthDate) || '',
                  state: player?.state || '',
                  section: getEnumKeyFromValue(MappedSectionEnum, player?.section) as any,
                  district: getEnumKeyFromValue(MappedDistrictEnum, player?.district) as any,
                  city: player?.city || '',
                },
              ],
              rank: +newPosition,
            },
          },
        });
      }
    } catch (error) {}

    refetch?.();
    refetchRanklist();
    onClose();
  };

  if (loadingRanklist) {
    return (
      <Grid container justifyContent="center">
        <Spinner />
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Body size="lg" bold>
          {t('current position')}: {player?.rank}
        </Body>
        <Body size="lg" bold spacing={{ margins: { md: 'top' } }}>
          {t('new list position')}
        </Body>
        <Body size="lg" spacing={{ margins: { xxs: 'top' } }} color={error ? Body.color.ERROR : Body.color.DEFAULT}>
          {t('assign position range', { start: 1, end: controlProps.totalItems })}
        </Body>
        <CustomGrid container item xs={6} spacing={{ margins: { xs: 'top' } }}>
          <TextInput
            value={newPosition}
            onChange={(e) => setNewPosition(e.target.value)}
            placeholder={t('enter a number')}
            disableUnderline
            outlined
            fluid
            error={Boolean(error || samePositionError)}
          />
        </CustomGrid>
        {samePositionError && (
          <Body size="lg" spacing={{ margins: { xxs: 'top' } }} color={Body.color.ERROR}>
            {samePositionError}
          </Body>
        )}
        <CustomGrid container spacing={{ margins: { md: 'top' } }} justify="flex-end">
          <Button level="tertiary" onClick={onClose} type="button">
            {t('close')}
          </Button>
          <Button
            spacing={{ margins: { sm: 'left' } }}
            type="submit"
            loading={addingRankListItem || movingRankListItem}
          >
            {t('confirm new position')}
          </Button>
        </CustomGrid>
      </Grid>
    </form>
  );
};
