export const sortParticipants = (participants) => {
  if (!participants) return [];

  return [...participants].sort((a, z) => {
    const [, aLastName] = a?.name ? a.name.toLowerCase().split(' ') : [''];
    const [, zLastName] = z?.name ? z.name.toLowerCase().split(' ') : [''];

    // Handle empty or missing names by placing them last
    if (!aLastName && zLastName) return 1;
    if (aLastName && !zLastName) return -1;
    if (!aLastName && !zLastName) return 0;

    return aLastName.localeCompare(zLastName);
  });
};
