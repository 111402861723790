import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableColumn } from 'src/components/drag-and-drop-table/drag-and-drop-table';
import Icon from 'src/components/icon/icon';
import Spinner from 'src/components/spinner/spinner';
import { ListTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import {
  Ranklist_ranklist_rankingItemsPaginated_items_participants as Participant,
  Ranklist_ranklist,
  Ranklist_ranklist_rankingItemsPaginated_items as RanklistItem,
} from 'src/graphql-types/Ranklist';
import { useOrgLevel } from 'src/utils/auth';
import { formatDate } from 'src/utils/helper/membership';
import { retrieveColumnPreference } from 'src/utils/storage/local-storage';

import { MoveDialog } from './components/move-dialog';
import { useRemoveRankListItem } from './rankings-run.api';
import * as styles from './rankings-run.module.less';
import {
  handleFullNamesAndSort,
  handleWordSplit,
  isTeamDoubles,
  navigateToPlayerProfile,
  sortParticipants,
} from './rankings-run.utils';

interface ColumnsProps {
  ranklist: Ranklist_ranklist | undefined;
  isVisibilityStatusPublished?: boolean;
  getDisabledConditions: (item: RanklistItem) => boolean[];
  setSelectedPlayer: (player: (Participant & { rank: number }) | null) => void;
}

const TABLE_ID = 'rankings_run';

export function useDraggableColumns({
  ranklist,
  isVisibilityStatusPublished,
  getDisabledConditions,
  setSelectedPlayer,
}: ColumnsProps) {
  const { t } = useTranslation();
  const { isNational } = useOrgLevel();
  const storedColumnPreference = useMemo(() => retrieveColumnPreference(TABLE_ID), []);
  const [removeRankListItem, { loading: removingItem }] = useRemoveRankListItem({
    listId: ranklist?.id as string,
  });
  const isJuniorStandings =
    ranklist?.listType === ListTypeEnum.STANDING && ranklist?.playerType === PlayerTypeEnum.JUNIOR;
  const isL2Qualifer = ranklist?.listType === ListTypeEnum.L2_QUALIFIER;

  const columns: TableColumn<
    RanklistItem & {
      'move icon': string;
      'usta id': string;
      name: string;
      'team name': string;
      delete: string;
    }
  >[] = [
    {
      accessor: 'rank',
      id: 'rank',
      Header: () => t('rank'),
      Cell: ({ row }) => row?.original?.rank,
      // sort: false,
      columnToggle: {
        checked: true,
        disabled: true,
      },
    },
    {
      accessor: 'move icon',
      id: 'move icon',
      Header: () => ' ',
      Cell: ({ row }) => (row?.original?.wildcard ? <Icon name="md-double-arrow" /> : null),
      // sort: false
    },
    {
      accessor: 'usta id',
      id: 'usta id',
      Header: () => t('usta id'),
      Cell: ({ row }) => {
        const [firstParticipant, secondParticipant] = sortParticipants(row?.original?.participants);
        return handleWordSplit(firstParticipant.itemId, secondParticipant?.itemId);
      },
      // sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['usta id'] ?? true,
        index: 9,
      },
    },
    {
      accessor: 'team name',
      id: 'team name',
      Header: () => t('name'),
      Cell: ({ row }) => {
        const [firstParticipantFullName, secondParticipantFullName = [], firstParticipantId, secondParticipantId] =
          handleFullNamesAndSort(row?.original?.participants);
        const [, firstParticipantLastName] = firstParticipantFullName;
        const [, secondParticipantLastName = null] = secondParticipantFullName;

        if (!secondParticipantFullName) {
          return firstParticipantLastName;
        }

        return (
          <Grid container alignItems="center">
            <button
              className={styles.linkButton}
              onClick={() => navigateToPlayerProfile(firstParticipantId, ranklist?.id)}
            >
              {firstParticipantLastName}
            </button>
            /
            <button
              className={styles.linkButton}
              onClick={() => navigateToPlayerProfile(secondParticipantId, ranklist?.id)}
            >
              {secondParticipantLastName}
            </button>
          </Grid>
        );
      },
      show: ranklist?.matchFormatType === 'TEAM',
      //   // sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['team name'] ?? true,
        disabled: true,
      },
    },
    {
      accessor: 'name',
      id: 'name',
      Header: () => t('name'),
      Cell: ({ row }) => {
        const [firstParticipant] = row?.original?.participants ?? [];
        const [first, last] = firstParticipant?.name?.split(' ') ?? ([] as (string | undefined)[]);

        return (
          <Grid container alignItems="center">
            <button
              className={styles.linkButton}
              onClick={() => navigateToPlayerProfile(firstParticipant?.itemId as string, ranklist?.id)}
            >
              {`${last?.toUpperCase() || ''}, ${first}`}
            </button>
          </Grid>
        );
      },
      show: !isTeamDoubles(ranklist?.matchFormat, ranklist?.matchFormatType),
      //   // sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['name'] ?? true,
        disabled: true,
      },
    },

    {
      accessor: 'birthYear',
      id: 'birthYear',
      Header: () => t('birth year'),
      Cell: ({ row }) => {
        const [firstParticipant, secondParticipant] = sortParticipants(row?.original?.participants);

        return handleWordSplit(
          formatDate(firstParticipant.birthDate, 'YYYY'),
          secondParticipant ? formatDate(secondParticipant?.birthDate, 'YYYY') : null,
        );
      },
      //   // sort: false,
      columnToggle: { checked: storedColumnPreference?.['birthYear'] ?? true },
    },
    {
      accessor: 'section',
      id: 'section',
      Header: () => t('section'),
      Cell: ({ row }) => {
        const [firstParticipant, secondParticipant] = sortParticipants(row?.original?.participants);
        const hasSameValue = firstParticipant?.section === secondParticipant?.section;

        return hasSameValue
          ? firstParticipant?.section
          : handleWordSplit(firstParticipant?.section, secondParticipant?.section);
      },
      // sort: false,
      columnToggle: { checked: storedColumnPreference?.['section'] ?? true },
    },
    {
      accessor: 'sectionPosition',
      id: 'sectionPosition',
      Header: () => t('section position'),
      Cell: ({ row }) => row?.original?.sectionRank || t('n/a'),
      //   // sort: false,
      show: !isL2Qualifer,
      columnToggle: { checked: storedColumnPreference?.['sectionPosition'] ?? true },
    },
    {
      accessor: 'district',
      id: 'district',
      Header: () => t('district'),
      Cell: ({ row }) => {
        const [firstParticipant, secondParticipant] = sortParticipants(row?.original?.participants);
        const hasSameValue = firstParticipant.district === secondParticipant?.district;

        return hasSameValue
          ? firstParticipant.district
          : handleWordSplit(firstParticipant.district, secondParticipant?.district);
      },
      // sort: false,
      columnToggle: { checked: storedColumnPreference?.['district'] ?? true },
    },
    {
      accessor: 'districtPosition',
      id: 'districtPosition',
      Header: () => t('district position'),
      Cell: ({ row }) => row?.original?.districtRank || t('n/a'),
      // sort: false,
      show: !isL2Qualifer,
      columnToggle: { checked: storedColumnPreference?.['districtPosition'] ?? true },
    },
    {
      accessor: 'singlesPoints',
      id: 'singlesPoints',
      Header: () => t('singles points'),
      Cell: ({ row }) => String(row?.original?.points?.singles || 0),
      show: isJuniorStandings,
      // sort: false,
      columnToggle: { checked: storedColumnPreference?.['singlesPoints'] ?? true },
    },
    {
      accessor: 'doublesPoints',
      id: 'doublesPoints',
      Header: () => t('doubles points'),
      Cell: ({ row }) => String(row?.original?.points?.doubles || 0),
      show: isJuniorStandings,
      // sort: false,
      columnToggle: { checked: storedColumnPreference?.['doublesPoints'] ?? true },
    },
    {
      accessor: 'bonusPoints',
      id: 'bonusPoints',
      Header: () => t('bonus points'),
      Cell: ({ row }) => String(row?.original?.points?.bonus || 0),
      show: isJuniorStandings,
      // sort: false,
      columnToggle: { checked: storedColumnPreference?.['bonusPoints'] ?? true },
    },
    {
      accessor: 'points',
      id: 'points',
      Header: () => t('points'),
      Cell: ({ row }) => String(row?.original?.points?.total || 0),
      // sort: false,
      show: !isL2Qualifer,
      columnToggle: { checked: storedColumnPreference?.['points'] ?? true },
    },
    {
      accessor: 'actions',
      id: 'actions',
      Header: () => t('actions'),
      Cell: ({ row }) => {
        const disabledConditions = [...getDisabledConditions(row?.original), removingItem];
        return (
          <MoveDialog
            ranklistItem={row?.original}
            disabledConditions={disabledConditions}
            setSelectedPlayer={setSelectedPlayer}
          />
        );
      },
      // sort: false,
      show:
        isNational &&
        !isVisibilityStatusPublished &&
        ranklist?.listType &&
        [ListTypeEnum.QUOTA, ListTypeEnum.DIRECT_ACCEPTANCE, ListTypeEnum.L2_QUALIFIER].includes(ranklist?.listType),
    },
    {
      accessor: 'delete',
      id: 'delete',
      Header: () => ' ',
      Cell: ({ row }) => {
        if (removingItem) {
          return <Spinner size="sm" />;
        }

        return (
          <Icon
            name="sm-delete"
            className={styles.deleteIcon}
            onClick={() =>
              removeRankListItem({
                variables: { itemObjectId: row?.original?.id, listId: ranklist?.id as string },
              })
            }
          />
        );
      },
      show: isNational,
    },
  ];

  return columns;
}
