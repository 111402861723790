import dayjs from 'dayjs';
import { TFunction } from 'i18next';

interface GetRankingPeriodProps {
  start: Date | null;
  end: Date | null;
  t: TFunction;
}

const getRankingPeriod = ({ start, end, t }: GetRankingPeriodProps) => {
  if (!start || !end) {
    return '';
  }

  const isSameYear = new Date(start).getFullYear() === new Date(end).getFullYear();
  const translationString = isSameYear ? 'ranklist date range' : 'ranklist date range diff year';

  return t(translationString, {
    start: dayjs.utc(start),
    end: dayjs.utc(end),
  });
};

export default getRankingPeriod;
