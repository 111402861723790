import { TFunction } from 'react-i18next';
import { SectionEnum } from 'src/graphql-types/globalRankingTypes';

export const getSectionEnumOptions = (t: TFunction): { value: SectionEnum | ''; label: string }[] => {
  // TODO: move these to client config
  return [
    { value: '', label: t('any section') },
    { value: SectionEnum.CARIBBEAN, label: t('caribbean') },
    { value: SectionEnum.EASTERN, label: t('eastern') },
    { value: SectionEnum.FLORIDA, label: t('florida') },
    { value: SectionEnum.HAWAII_PACIFIC, label: t('hawaii pacific') },
    { value: SectionEnum.INTERMOUNTAIN, label: t('intermountain') },
    { value: SectionEnum.INTERNATIONAL, label: t('international') },
    { value: SectionEnum.MID_ATLANTIC, label: t('mid atlantic') },
    { value: SectionEnum.MIDDLE_STATES, label: t('middle states') },
    { value: SectionEnum.MIDWEST, label: t('midwest') },
    { value: SectionEnum.MISSOURI_VALLEY, label: t('missouri valley') },
    { value: SectionEnum.NEW_ENGLAND, label: t('new england') },
    { value: SectionEnum.NORTHERN, label: t('northern') },
    { value: SectionEnum.NORTHERN_CALIFORNIA, label: t('northern california') },
    { value: SectionEnum.PACIFIC_NW, label: t('pacific nw') },
    { value: SectionEnum.SOUTHERN, label: t('sourthern') },
    {
      value: SectionEnum.SOUTHERN_CALIFORNIA,
      label: t('sourthern california'),
    },
    { value: SectionEnum.SOUTHWEST, label: t('southwest') },
    { value: SectionEnum.TEXAS, label: t('texas') },
    { value: SectionEnum.UNASSIGNED, label: t('unassigned') },
  ];
};
