import { CSVValueTransform } from 'src/components/generate-report/generate-report';

// For multiple participants per ranking item, display fields as "p1Field / p1Field"
const slashParticipantField = (field: string) => {
  return {
    key: 'participants',
    transforms: [
      {
        operation: CSVValueTransform.ARRAY_JOIN,
        parameters: [
          { key: 'fieldPath', value: field },
          { key: 'delimiter', value: ' / ' },
        ],
      },
    ],
  };
};

export default slashParticipantField;
