import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as autocompleteStyles from 'src/components/autocomplete/autocomplete.module.less';
import Button from 'src/components/button/button';
import {
  MUIDialogActions as DialogActions,
  MUIDialogContent as DialogContent,
  MUIDialogTitle as DialogTitle,
} from 'src/components/dialog/dialog';
import { SEARCH_GROUP, SEARCH_PLAYERS } from 'src/components/players/players-queries';
import * as styles from 'src/components/ranking-entry/ranking-entry.module.less';
import { findGroup, findGroup_findGroup_results, findGroupVariables } from 'src/graphql-types/findGroup';
import {
  GetOrganisationByTeamId,
  GetOrganisationByTeamIdVariables,
} from 'src/graphql-types/memberships/GetOrganisation';
import { SearchPeople, SearchPeople_searchPeople_results, SearchPeopleVariables } from 'src/graphql-types/SearchPeople';

import { Dropdown, TextInput } from '@clubspark-react/clubspark-react-tools';

import { GET_ORGANISATION_BY_TEAM_ID } from './ranking-select-player-modal.queries';
import RankingSelectPlayerTable from './ranking-select-player-table';
import RankingSelectTeamTable from './ranking-select-team-table';

type ModalProps = {
  openModal: boolean;
  isTeam: boolean;
  onClose: () => void;
  preparePlayerData: (label, value, teamName, teamId) => void;
  onSave: () => void;
};

const RankingSelectPlayerModal = ({ openModal, onClose, preparePlayerData, onSave, isTeam }: ModalProps) => {
  const [selectValue, setSelectValue] = useState('TEAMS');
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [groups, setGroups] = React.useState<findGroup_findGroup_results[]>([]);
  const [page, setPage] = React.useState(0);
  // @ts-ignore
  const [players, setPlayers] = React.useState<SearchPeople>([]);
  const [playerSelected, setPlayerSelected] = React.useState<SearchPeople_searchPeople_results>();
  const [teamData, setTeamData] = React.useState<GetOrganisationByTeamId>();
  const [perPage, setPerPage] = React.useState(5);
  const [search, setSearch] = React.useState('');
  const [validationError, setValidationError] = React.useState('');
  const [dropdownValue, setDropdownValue] = React.useState('');

  const [getPlayers, { loading }] = useLazyQuery<SearchPeople, SearchPeopleVariables>(SEARCH_PLAYERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      //  This remapping is used to be able to reset data to empty array
      setPlayers(data);
    },
  });
  const [getTeams, { loading: loadingTeams }] = useLazyQuery<findGroup, findGroupVariables>(SEARCH_GROUP, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      //  This remapping is used to be able to reset data to empty array
      setGroups(data?.findGroup?.results);
    },
  });
  const [getTeamDetails, { loading: loadingTeamDetails, data: teamDetails }] = useLazyQuery<
    GetOrganisationByTeamId,
    GetOrganisationByTeamIdVariables
  >(GET_ORGANISATION_BY_TEAM_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      //  This remapping is used to be able to reset data to empty array
      setTeamData(data);
    },
  });

  const onChangeDropdown = (data) => {
    //  Reset search input when dropdown change
    setSelectValue(data.value);
    resetAllModalData();
  };

  useEffect(() => {
    //  Reset modal data on close
    if (!openModal) {
      resetAllModalData();
      setSelectValue('TEAMS');
    }
  }, [openModal]);

  //  Call queries when pagination change
  useEffect(() => {
    if (search || dropdownValue) {
      // Prevent calling query when component mounts
      if (selectValue === 'TEAMS') {
        getPlayers({
          variables: {
            filter: { group: { id: { eq: dropdownValue } } },
            pageArgs: {
              limit: perPage,
              skip: page * perPage,
            },
          },
        });
      } else {
        getPlayers({
          variables: {
            filter: { search: { normalized: search } },
            pageArgs: {
              limit: perPage,
              skip: page * perPage,
            },
          },
        });
      }
    }
  }, [page, perPage]);

  const resetAllModalData = () => {
    setSearch('');
    setDropdownValue('');
    setGroups([]);
    // @ts-ignore
    setPlayers([]);
    // @ts-ignore
    setTeamData({});
    setPerPage(5);
    setPage(0);
    setSelectedPlayer(undefined);
  };

  const searchPlayers = () => {
    //  Remove validation errors if there is any
    setValidationError('');

    if (!search) {
      setValidationError(t('enter player name'));
    } else {
      getPlayers({
        variables: {
          filter: { search: { normalized: search } },
          pageArgs: {
            limit: perPage,
            skip: page * perPage,
          },
        },
      });
    }
  };

  const onChangeRows = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setSelectedPlayer = (player) => {
    setPlayerSelected(player);
    const label = player?.standardGivenName + ' ' + player?.standardFamilyName;
    const value = player?.personId;
    const teamName = player?.groups.find((grp) => grp.year);
    const { groupId: teamId } = player?.groups.find((grp) => grp.groupId) || {};
    preparePlayerData(label, value, teamName?.groupName, teamId);
  };

  const setSelectedTeam = (team) => {
    setPlayerSelected(team);
    const label = team?.organisationByTeamId?.name;
    const value = team?.organisationByTeamId?.id;
    preparePlayerData(label, value, label, value);
  };

  const searchTeams = _.debounce((event, newValue) => {
    if (open && newValue.length >= 3) {
      getTeams({
        variables: {
          filter: {
            groupName: {
              contains: newValue,
            },
          },
        },
      });
    } else {
      setGroups([]);
    }
  }, 400);

  const selectGroup = (event, value) => {
    if (value) {
      setPlayerSelected(undefined); //  Reset selected player
      setDropdownValue(value.groupId);
      if (isTeam) {
        getTeamDetails({
          variables: {
            teamId: value.groupId,
          },
        });
      } else {
        getPlayers({
          variables: {
            filter: { group: { id: { eq: value.groupId } } },
            pageArgs: {
              limit: perPage,
            },
          },
        });
      }
    }
  };
  return (
    <Dialog open={openModal} onClose={onClose} maxWidth={isTeam && !_.isEmpty(teamData) ? 'md' : 'sm'} fullWidth>
      <DialogTitle onClose={onClose} title={isTeam ? t('add team') : t('add player')} titleStyle={styles.boldText} />
      <DialogContent>
        <Grid container spacing={1} style={{ paddingBottom: 50 }}>
          {!isTeam && (
            <Grid item xs={4}>
              <Dropdown
                style={{ width: '100%' }}
                selected={selectValue}
                options={[
                  { label: t('teams'), value: 'TEAMS' },
                  { label: t('players'), value: 'PLAYERS' },
                ]}
                onSelect={(o) => onChangeDropdown(o)}
              />
            </Grid>
          )}
          <Grid item xs={isTeam ? 12 : 8}>
            {selectValue === 'PLAYERS' && (
              <>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    value={search}
                    placeholder="Name"
                    className={styles.textField}
                    onChange={(t) => setSearch(t.target.value)}
                    spacing={{ margins: { xs: 'right' } }}
                    disableUnderline
                  />
                  <Button style={{ height: 40, marginLeft: 10 }} onClick={() => searchPlayers()} type="button">
                    Search
                  </Button>
                </div>
                <small>{validationError}</small>
              </>
            )}
            {selectValue === 'TEAMS' && (
              <Autocomplete
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onInputChange={(event, newInputValue) => searchTeams(event, newInputValue)}
                onChange={(event, newValue) => selectGroup(event, newValue)}
                options={groups}
                getOptionLabel={(option) => option?.groupName}
                isOptionEqualToValue={(option, value) => option.groupName === value.groupName}
                loading={loadingTeams}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.groupName, inputValue);
                  const parts = parse(option.groupName, matches);

                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 'bold' : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    placeholder={'Name'}
                    className={autocompleteStyles.textFieldRoot}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        notchedOutline: autocompleteStyles.notchedOutline,
                      },
                    }}
                  />
                )}
                classes={{
                  root: autocompleteStyles.root,
                  inputRoot: autocompleteStyles.inputRoot,
                  input: autocompleteStyles.input,
                  inputFocused: autocompleteStyles.focused,
                  popupIndicator: autocompleteStyles.icon,
                }}
              />
            )}
          </Grid>
        </Grid>
        {isTeam && (
          <RankingSelectTeamTable team={teamData} loading={loadingTeamDetails} setSelectedPlayer={setSelectedTeam} />
        )}
        {!isTeam && (
          <RankingSelectPlayerTable
            players={players}
            loading={loading}
            page={page}
            perPage={perPage}
            onChangeRows={onChangeRows}
            onChangePage={onChangePage}
            setSelectedPlayer={setSelectedPlayer}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button level="secondary" style={{ height: 32 }} onClick={() => onClose()} type="button">
          {t('cancel')}
        </Button>
        <Button disabled={!playerSelected} style={{ height: 32 }} onClick={() => onSave()} type="button">
          {isTeam ? t('add team') : t('add player')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RankingSelectPlayerModal;
