import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import Icon from 'src/components/icon/icon';
import Spinner from 'src/components/spinner/spinner';
import { ListTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import {
  Ranklist_ranklist_rankingItemsPaginated_items_participants as Participant,
  Ranklist_ranklist,
  Ranklist_ranklist_rankingItemsPaginated_items as RanklistItem,
} from 'src/graphql-types/Ranklist';
import { useOrgLevel } from 'src/utils/auth';
import { formatDate } from 'src/utils/helper/membership';
import { retrieveColumnPreference } from 'src/utils/storage/local-storage';

import { MoveDialog } from './components/move-dialog';
import { useRemoveRankListItem } from './rankings-run.api';
import * as styles from './rankings-run.module.less';
import {
  handleFullNamesAndSort,
  handleWordSplit,
  isTeamDoubles,
  navigateToPlayerProfile,
  sortParticipants,
} from './rankings-run.utils';

const tableId = 'rankings_run';

interface ColumnsProps {
  ranklist: Ranklist_ranklist | undefined;
  isVisibilityStatusPublished?: boolean;
  getDisabledConditions: (item: RanklistItem) => boolean[];
  setSelectedPlayer: (player: (Participant & { rank: number }) | null) => void;
}

export function useColumns({
  ranklist,
  isVisibilityStatusPublished,
  getDisabledConditions,
  setSelectedPlayer,
}: ColumnsProps) {
  const { t } = useTranslation();
  const storedColumnPreference = useMemo(() => retrieveColumnPreference(tableId), []);
  const { isNational, isSection } = useOrgLevel();
  const [removeRankListItem, { loading: removingItem }] = useRemoveRankListItem({
    listId: ranklist?.id as string,
  });
  const isJuniorStandings =
    ranklist?.listType === ListTypeEnum.STANDING && ranklist?.playerType === PlayerTypeEnum.JUNIOR;

  const columns: DataCols<RanklistItem> = [
    {
      key: 'rank',
      title: t('rank'),
      getValue: (m) => m.rank,
      sort: false,
      columnToggle: {
        checked: true,
        disabled: true,
      },
    },
    {
      key: 'move icon',
      title: ' ',
      getValue: (m) => (m.wildcard ? <Icon name="md-double-arrow" /> : null),
      sort: false,
    },
    {
      key: 'usta id',
      title: t('usta id'),
      getValue: (m) => {
        const [firstParticipant, secondParticipant] = sortParticipants(m.participants);
        return handleWordSplit(firstParticipant.itemId, secondParticipant?.itemId);
      },
      sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['usta id'] ?? true,
        index: 9,
      },
    },
    {
      key: 'team name',
      title: t('name'),
      getValue: (m) => {
        const [firstParticipantFullName, secondParticipantFullName = [], firstParticipantId, secondParticipantId] =
          handleFullNamesAndSort(m.participants);
        const [, firstParticipantLastName] = firstParticipantFullName;
        const [, secondParticipantLastName = null] = secondParticipantFullName;

        if (!secondParticipantFullName) {
          return firstParticipantLastName;
        }

        return (
          <Grid container alignItems="center">
            <button
              className={styles.linkButton}
              onClick={() => navigateToPlayerProfile(firstParticipantId, ranklist?.id)}
            >
              {firstParticipantLastName}
            </button>
            /
            <button
              className={styles.linkButton}
              onClick={() => navigateToPlayerProfile(secondParticipantId, ranklist?.id)}
            >
              {secondParticipantLastName}
            </button>
          </Grid>
        );
      },
      hidden: ranklist?.matchFormatType !== 'TEAM',
      sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['team name'] ?? true,
        disabled: true,
      },
    },
    {
      key: 'name',
      title: t('name'),
      getValue: (m) => {
        const [firstParticipant] = m.participants ?? [];
        const [first, last] = firstParticipant?.name?.split(' ') ?? ([] as (string | undefined)[]);

        return (
          <Grid container alignItems="center">
            <button
              className={styles.linkButton}
              onClick={() => navigateToPlayerProfile(firstParticipant?.itemId as string, ranklist?.id)}
            >
              {`${last?.toUpperCase() || ''}, ${first}`}
            </button>
          </Grid>
        );
      },
      hidden: isTeamDoubles(ranklist?.matchFormat, ranklist?.matchFormatType),
      sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['name'] ?? true,
        disabled: true,
      },
    },

    {
      key: 'birthYear',
      title: t('birth year'),
      getValue: (m) => {
        const [firstParticipant, secondParticipant] = sortParticipants(m.participants);

        return handleWordSplit(
          formatDate(firstParticipant.birthDate, 'YYYY'),
          secondParticipant ? formatDate(secondParticipant?.birthDate, 'YYYY') : null,
        );
      },
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['birthYear'] ?? true },
    },
    {
      key: 'section',
      title: t('section'),
      getValue: (m) => {
        const [firstParticipant, secondParticipant] = sortParticipants(m.participants);
        const hasSameValue = firstParticipant?.section === secondParticipant?.section;

        return hasSameValue
          ? firstParticipant?.section
          : handleWordSplit(firstParticipant?.section, secondParticipant?.section);
      },
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['section'] ?? true },
    },
    {
      key: 'sectionPosition',
      title: t('section position'),
      getValue: (m) => m.sectionRank || t('n/a'),
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['sectionPosition'] ?? true },
    },
    {
      key: 'district',
      title: t('district'),
      getValue: (m) => {
        const [firstParticipant, secondParticipant] = sortParticipants(m.participants);
        const hasSameValue = firstParticipant.district === secondParticipant?.district;

        return hasSameValue
          ? firstParticipant.district
          : handleWordSplit(firstParticipant.district, secondParticipant?.district);
      },
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['district'] ?? true },
    },
    {
      key: 'districtPosition',
      title: t('district position'),
      getValue: (m) => m.districtRank || t('n/a'),
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['districtPosition'] ?? true },
    },
    {
      key: 'singlesPoints',
      title: t('singles points'),
      getValue: (m) => m.points?.singles,
      hidden: !isJuniorStandings,
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['singlesPoints'] ?? true },
    },
    {
      key: 'doublesPoints',
      title: t('doubles points'),
      getValue: (m) => m.points?.doubles,
      hidden: !isJuniorStandings,
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['doublesPoints'] ?? true },
    },
    {
      key: 'bonusPoints',
      title: t('bonus points'),
      getValue: (m) => m.points?.bonus,
      hidden: !isJuniorStandings,
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['bonusPoints'] ?? true },
    },
    {
      key: 'points',
      title: t('points'),
      getValue: (m) => m.points?.total ?? '',
      sort: false,
      columnToggle: { checked: storedColumnPreference?.['points'] ?? true },
    },
    {
      key: 'actions',
      title: t('actions'),
      getValue: (m) => {
        const disabledConditions = [...getDisabledConditions(m), removingItem];
        return (
          <MoveDialog ranklistItem={m} disabledConditions={disabledConditions} setSelectedPlayer={setSelectedPlayer} />
        );
      },
      sort: false,
      hidden:
        (!isNational && !isSection) ||
        isVisibilityStatusPublished ||
        !ranklist?.listType ||
        ![ListTypeEnum.QUOTA, ListTypeEnum.DIRECT_ACCEPTANCE].includes(ranklist?.listType),
    },
    {
      key: 'delete',
      title: ' ',
      sort: false,
      getValue: (m) => {
        if (removingItem) {
          return <Spinner size="sm" />;
        }

        return (
          <Icon
            name="sm-delete"
            className={styles.deleteIcon}
            onClick={() =>
              removeRankListItem({
                variables: { itemObjectId: m.id, listId: ranklist?.id as string },
              })
            }
          />
        );
      },
      hidden: ranklist?.listType !== ListTypeEnum.DIRECT_ACCEPTANCE,
    },
  ];

  return columns;
}
