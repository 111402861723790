// extracted by mini-css-extract-plugin
export var annotation = "rankings-add-player-panel-module--annotation--1c4b8";
export var bodyBase = "rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var bodyLarge = "rankings-add-player-panel-module--body-large--96c6f rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var bodyLargeBold = "rankings-add-player-panel-module--body-large-bold--feeac rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var bodyRegular = "rankings-add-player-panel-module--body-regular--2db24 rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var bodyRegularBold = "rankings-add-player-panel-module--body-regular-bold--a9081 rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var bodySmall = "rankings-add-player-panel-module--body-small--450d5 rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var bodySmallBold = "rankings-add-player-panel-module--body-small-bold--f5d06 rankings-add-player-panel-module--body-base--ed352 rankings-add-player-panel-module--site-font--aea1f";
export var borderTop = "rankings-add-player-panel-module--border-top--5354a";
export var breakWordContainer = "rankings-add-player-panel-module--break-word-container--02ea5";
export var buttonIconBase = "rankings-add-player-panel-module--button-icon-base--51b80";
export var clickLink = "rankings-add-player-panel-module--click-link--c0c9d";
export var container = "rankings-add-player-panel-module--container--7be71";
export var deleteIcon = "rankings-add-player-panel-module--delete-icon--fdc0a";
export var dialogTitle = "rankings-add-player-panel-module--dialog-title--930df";
export var dropdownBase = "rankings-add-player-panel-module--dropdown-base--cc377";
export var dropdownSelectBase = "rankings-add-player-panel-module--dropdown-select-base--cdd41 rankings-add-player-panel-module--text-input--ac342";
export var flexCol = "rankings-add-player-panel-module--flex-col--fb62a";
export var formErrorMessage = "rankings-add-player-panel-module--form-error-message--38781";
export var h3 = "rankings-add-player-panel-module--h3--f061b";
export var h4 = "rankings-add-player-panel-module--h4--19387";
export var hoverLink = "rankings-add-player-panel-module--hover-link--aa072";
export var hoverRow = "rankings-add-player-panel-module--hover-row--d173c";
export var membershipContainer = "rankings-add-player-panel-module--membership-container--c415d rankings-add-player-panel-module--flex-col--fb62a rankings-add-player-panel-module--primary-border--6d264";
export var membershipHeader = "rankings-add-player-panel-module--membership-header--57859";
export var membershipHeading = "rankings-add-player-panel-module--membership-heading--95f6a";
export var membershipLabel = "rankings-add-player-panel-module--membership-label--bf3da";
export var moreFiltersBorderClass = "rankings-add-player-panel-module--more-filters-border-class--411d3";
export var pageBg = "rankings-add-player-panel-module--page-bg--ea485";
export var panelItem = "rankings-add-player-panel-module--panel-item--1d915";
export var pointer = "rankings-add-player-panel-module--pointer--c25f8";
export var primaryBorder = "rankings-add-player-panel-module--primary-border--6d264";
export var shadowBoxLight = "rankings-add-player-panel-module--shadow-box-light--7bccb";
export var siteFont = "rankings-add-player-panel-module--site-font--aea1f";
export var slideDownAndFade = "rankings-add-player-panel-module--slideDownAndFade--a6bb4";
export var slideLeftAndFade = "rankings-add-player-panel-module--slideLeftAndFade--32a8f";
export var slideRightAndFade = "rankings-add-player-panel-module--slideRightAndFade--fb8d5";
export var slideUpAndFade = "rankings-add-player-panel-module--slideUpAndFade--a6e4b";
export var statusDecoration = "rankings-add-player-panel-module--status-decoration--7d128";
export var textInput = "rankings-add-player-panel-module--text-input--ac342";
export var textInverted = "rankings-add-player-panel-module--text-inverted--7b1ba";
export var textMediumDark = "rankings-add-player-panel-module--text-medium-dark--b06fc";
export var tooltipFont = "rankings-add-player-panel-module--tooltipFont--7acd2";
export var unstyledButton = "rankings-add-player-panel-module--unstyled-button--326bf";