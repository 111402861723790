import React, { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import update from 'immutability-helper';
import { DropTargetMonitor } from 'react-dnd';
import { Column, useFlexLayout, useTable } from 'react-table';
import { useColumnSelector } from 'src/components/admin-table/admin-table';
import Spinner from 'src/components/spinner/spinner';
import { TableColumnButton } from 'src/components/table-column-button/table-column-button';
import { ControlProps } from 'src/components/table-controls/table-controls';
import { TBody, TH, THead, TPagination, TR } from 'src/components/table/table';
import { retrieveRowsPerPage, storeRowsPerPage } from 'src/utils/storage/local-storage';

import * as styles from './drag-and-drop-table.module.less';
import { Row } from './row';

// extending 3rd party library typings
export type TableColumn<Data extends object = {}> = Column<Data>;

interface Props<Data extends object = {}> {
  columns: Array<TableColumn<Data>>;
  data: any[];
  disabled?: boolean;
  loading?: boolean;
  onRowClick?: (row: Data) => void;
  onDrop?: (item: any, monitor: DropTargetMonitor, row: any) => void;
  controls?: ControlProps;
  rowsPerPageOptions?: number[];
  handlePageChange?: (page: number) => void;
  hiddenColumns?: string[];
  hideDragRef?: boolean;
  rowsPerPageExpanded?: boolean;
  columnSelectorId?: string;
}

const isColumnHiddenManually = (colsToggle: any, accessor: string) => {
  const col = colsToggle.find((col) => col.accessor === accessor);
  return col?.columnToggle?.checked === false;
};

export function DragAndDropTable({
  columns,
  data,
  onRowClick,
  disabled,
  onDrop,
  loading,
  controls,
  rowsPerPageOptions,
  handlePageChange,
  hiddenColumns,
  hideDragRef,
  rowsPerPageExpanded,
  columnSelectorId,
}: Props) {
  const [records, setRecords] = useState(data);
  const [page, setPage] = useState(0);
  const storedRowsPerPage = useMemo(() => retrieveRowsPerPage(), []);
  const defaultRows = rowsPerPageOptions ? rowsPerPageOptions[0] : 10;
  const [rowsPerPage, setRowsPerPage] = useState(storedRowsPerPage ?? defaultRows);
  const { colsToggle, setColsToggle } = useColumnSelector(columns, columnSelectorId);

  const cols = useMemo(() => {
    return columns?.filter((col) => !isColumnHiddenManually(colsToggle, col.accessor as string));
  }, [columns, colsToggle]);

  React.useEffect(() => {
    setRecords(data);
  }, [data]);

  const handleChangePage = useCallback(
    (_, newPage: number) => {
      setPage(newPage);
      handlePageChange?.(newPage);
    },
    [setPage, handlePageChange],
  );
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [setPage, setRowsPerPage],
  );

  React.useEffect(() => {
    // This prevents tables that don't have rowsPerPageExpanded from breaking
    if (rowsPerPage !== 100) storeRowsPerPage(rowsPerPage);
  }, [rowsPerPage]);

  const { onControlChange } = controls ?? {};

  React.useEffect(() => {
    onControlChange?.({
      pagination: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      },
      // sorting: sort
    });
  }, [
    page,
    rowsPerPage,
    onControlChange,
    // , sort
  ]);

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data: records,
      columns: cols,
      getRowId,
      initialState: {
        ...(hiddenColumns ? { hiddenColumns } : {}),
      },
    },
    useFlexLayout,
  );

  function moveRow(dragIndex: number, hoverIndex: number) {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      }),
    );
  }

  return (
    <>
      {columnSelectorId && (
        <Grid container justifyContent="flex-end">
          <TableColumnButton cols={colsToggle} setCols={setColsToggle} />
        </Grid>
      )}
      <table {...getTableProps()} className={styles.table}>
        {!loading && (
          <THead>
            {headerGroups.map((headerGroup) => (
              <TR {...headerGroup.getHeaderGroupProps()}>
                <TH />
                {headerGroup.headers.map((column) => (
                  <TH {...column.getHeaderProps()}>{column.render('Header')}</TH>
                ))}
              </TR>
            ))}
          </THead>
        )}
        {!loading && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              return (
                (prepareRow(row) as any) || (
                  <Row
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    onDrop={onDrop}
                    {...row.getRowProps()}
                    onClick={!onRowClick ? undefined : () => onRowClick(row)}
                    disabled={disabled}
                    hideDragRef={hideDragRef}
                  />
                )
              );
            })}
          </tbody>
        )}
        {loading && <Spinner />}
      </table>
      {controls && !controls?.paginaitonDisabled && (
        <TPagination
          count={controls?.totalItems ?? 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageExpanded={rowsPerPageExpanded}
        />
      )}
    </>
  );
}
