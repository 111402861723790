// extracted by mini-css-extract-plugin
export var annotationIcon = "player-move-restrictions-dialog-module--annotation-icon--0e42d";
export var bodyBase = "player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var bodyLarge = "player-move-restrictions-dialog-module--body-large--e6b44 player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var bodyLargeBold = "player-move-restrictions-dialog-module--body-large-bold--e69ce player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var bodyRegular = "player-move-restrictions-dialog-module--body-regular--520ed player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var bodyRegularBold = "player-move-restrictions-dialog-module--body-regular-bold--87b59 player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var bodySmall = "player-move-restrictions-dialog-module--body-small--a66ad player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var bodySmallBold = "player-move-restrictions-dialog-module--body-small-bold--f4fe5 player-move-restrictions-dialog-module--body-base--cdc47 player-move-restrictions-dialog-module--site-font--714d9";
export var borderTop = "player-move-restrictions-dialog-module--border-top--2b7e5";
export var breakWordContainer = "player-move-restrictions-dialog-module--break-word-container--6a64a";
export var buttonContainer = "player-move-restrictions-dialog-module--button-container--aac8e";
export var buttonIconBase = "player-move-restrictions-dialog-module--button-icon-base--2c763";
export var clickLink = "player-move-restrictions-dialog-module--click-link--647b5";
export var container = "player-move-restrictions-dialog-module--container--60998";
export var dropdownBase = "player-move-restrictions-dialog-module--dropdown-base--bef5e";
export var dropdownSelectBase = "player-move-restrictions-dialog-module--dropdown-select-base--4e7e2 player-move-restrictions-dialog-module--text-input--d70a1";
export var flexCol = "player-move-restrictions-dialog-module--flex-col--7bd1a";
export var formErrorMessage = "player-move-restrictions-dialog-module--form-error-message--ec54d";
export var h3 = "player-move-restrictions-dialog-module--h3--d049a";
export var h4 = "player-move-restrictions-dialog-module--h4--90358";
export var hoverLink = "player-move-restrictions-dialog-module--hover-link--84026";
export var hoverRow = "player-move-restrictions-dialog-module--hover-row--89edf";
export var listItem = "player-move-restrictions-dialog-module--list-item--347f3";
export var membershipContainer = "player-move-restrictions-dialog-module--membership-container--74974 player-move-restrictions-dialog-module--flex-col--7bd1a player-move-restrictions-dialog-module--primary-border--5d393";
export var membershipHeader = "player-move-restrictions-dialog-module--membership-header--1f35b";
export var membershipHeading = "player-move-restrictions-dialog-module--membership-heading--f1a76";
export var membershipLabel = "player-move-restrictions-dialog-module--membership-label--162b6";
export var moreFiltersBorderClass = "player-move-restrictions-dialog-module--more-filters-border-class--edeeb";
export var pageBg = "player-move-restrictions-dialog-module--page-bg--7c144";
export var pointer = "player-move-restrictions-dialog-module--pointer--a4617";
export var primaryBorder = "player-move-restrictions-dialog-module--primary-border--5d393";
export var shadowBoxLight = "player-move-restrictions-dialog-module--shadow-box-light--93694";
export var siteFont = "player-move-restrictions-dialog-module--site-font--714d9";
export var slideDownAndFade = "player-move-restrictions-dialog-module--slideDownAndFade--925f1";
export var slideLeftAndFade = "player-move-restrictions-dialog-module--slideLeftAndFade--2c0d5";
export var slideRightAndFade = "player-move-restrictions-dialog-module--slideRightAndFade--c8fb5";
export var slideUpAndFade = "player-move-restrictions-dialog-module--slideUpAndFade--4754a";
export var statusDecoration = "player-move-restrictions-dialog-module--status-decoration--8f85b";
export var textInput = "player-move-restrictions-dialog-module--text-input--d70a1";
export var textInverted = "player-move-restrictions-dialog-module--text-inverted--c9028";
export var textMediumDark = "player-move-restrictions-dialog-module--text-medium-dark--2ae93";
export var tooltipFont = "player-move-restrictions-dialog-module--tooltipFont--ffaf2";
export var unstyledButton = "player-move-restrictions-dialog-module--unstyled-button--40308";