import React, { useCallback } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown from 'src/components/dropdown/dropdown';
import TextInput from 'src/components/input/input';
import { getDistrictEnumOptions } from 'src/components/rankings-run/rankings-run.utils';
import { getSectionEnumOptions } from 'src/components/rankings/utils/getSectionEnumOptions';
import { SectionEnum } from 'src/graphql-types/globalRankingTypes';

import { RunGroupQueryProps, UpdateFilterProps } from './ranking-run';

interface RankingRunFiltersProps {
  filters: RunGroupQueryProps;
  setFilter: (filter: Partial<UpdateFilterProps>) => void;
}

const RankingRunFilters: React.FC<RankingRunFiltersProps> = ({ filters, setFilter }) => {
  const { t } = useTranslation();

  const handleUpdateFilters = useCallback(
    (filterUpdate) => {
      setFilter(filterUpdate);
    },
    [setFilter],
  );

  return (
    <CustomGrid container spacing={{ margins: { sm: 'bottom' } }}>
      <Grid container item xs={8}>
        <CustomGrid item spacing={{ margins: { sm: 'right' } }}>
          <TextInput
            fluid
            placeholder={t('search by name or uaid')}
            outlined
            disableUnderline
            value={filters.search}
            onChange={(e) => handleUpdateFilters({ search: e.target.value })}
          />
        </CustomGrid>
        <CustomGrid item spacing={{ margins: { sm: 'right' } }}>
          <Dropdown
            selected={filters.section}
            options={getSectionEnumOptions(t)}
            onSelect={(o) => {
              if (!o.value) handleUpdateFilters({ district: '', section: o.value });
              if (o.value) handleUpdateFilters({ section: o.value });
            }}
          />
        </CustomGrid>
        <CustomGrid item spacing={{ margins: { sm: 'right' } }}>
          <Dropdown
            selected={filters.district}
            placeholder={t('any district')}
            options={getDistrictEnumOptions(filters?.section as SectionEnum, t)}
            onSelect={(o) => handleUpdateFilters({ district: o.value })}
            disabled={!filters.section}
          />
        </CustomGrid>
      </Grid>
    </CustomGrid>
  );
};

export default RankingRunFilters;
