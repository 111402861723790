import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import RankingEntry from 'src/components/ranking-entry/ranking-entry';
import USTARankingsRun from 'src/components/rankings-run/rankings-run';
import RankingRun from 'src/components/rankings/ranking-run/ranking-run';
import SEO from 'src/components/seo';
import { getClientConfig } from 'src/config/config';
import { useV2Rankings } from 'src/utils/useV2Rankings';

const RankingPage = () => {
  return (
    <Layout>
      <SEO title="Rankings" />
      <PrivateRoute>
        <Router basepath="/rankings">
          <RankingRoute path="/:rankingId" />
        </Router>
      </PrivateRoute>
    </Layout>
  );
};

interface RankingRouteProps extends RouteComponentProps {
  rankingId?: string;
}

const RankingRoute: React.FC<RankingRouteProps> = ({ rankingId }) => {
  if (getClientConfig().useItaRankings) return <RankingEntry rankingId={rankingId} />;

  return useV2Rankings() ? <RankingRun rankingId={rankingId as string} /> : <USTARankingsRun rankingId={rankingId} />;
};

export default RankingPage;
