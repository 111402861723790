import React, { useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import { useDebounce } from 'src/components/membership/membership';
import {
  TD_ARCHIVE_RANK_LISTS,
  TD_GET_RANK_LIST,
  TD_PUBLISH_RANK_LIST,
  TD_UNPUBLISH_RANK_LIST,
} from 'src/components/rankings/queries';
import { getLocalityFilters } from 'src/components/rankings/utils/getLocalityFilters';
import { getSearchFilter } from 'src/components/rankings/utils/getSearchFilter';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { TD_ArchiveRankLists } from 'src/graphql-types/TD_ArchiveRankLists';
import { TD_GetRankList, TD_GetRankList_td_rankList } from 'src/graphql-types/TD_GetRankList';
import { TD_PublishRankList } from 'src/graphql-types/TD_PublishRankList';
import { TD_UnpublishRankList } from 'src/graphql-types/TD_UnpublishRankList';
import ROUTES from 'src/utils/routes';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import RankingRunHeader from './ranking-run-header';
import RankingRunPanel from './ranking-run-panel';

export enum VisibilityStatusEnum {
  HIDDEN = 'HIDDEN',
  PUBLISHED = 'PUBLISHED',
}

export interface UpdateFilterProps {
  search: string;
  district: string;
  section: string;
}

export interface RunGroupQueryProps {
  rankListName?: string | null;
  rankListJobIds?: (string | null)[] | null;
  latestListMadeVisible?: string | null;
  search?: string | null;
  section?: string | null;
  district?: string | null;
}

const generateRankListQueryParams = (rankList: TD_GetRankList_td_rankList, query) => {
  const {
    listType,
    playerType,
    ageRestriction,
    gender,
    genderModifier,
    matchFormat,
    matchFormatType,
    playerLevel,
    familyCategory,
    region,
    divisionType,
  } = rankList ?? {};

  const { latestListMadeVisible, rankListJobIds } = query;

  const rankListJobParams = rankListJobIds?.length ? rankListJobIds.map((id) => `ranklistJobs=${id}`).join('&') : '';
  const rankListQueryParams = `?listType=${listType}&playerType=${playerType}&ageRestriction=${ageRestriction}&gender=${gender}&genderModifier=${genderModifier}&matchFormat=${matchFormat}&matchFormatType=${matchFormatType}&playerLevel=${playerLevel}&divisionType=${divisionType}&latestListMadeVisible=${latestListMadeVisible}&familyCategory=${familyCategory}&region=${region}`;

  return `${rankListQueryParams}&${rankListJobParams}`;
};

interface RankingRunProps {
  rankingId: string;
}

const RankingRun: React.FC<RankingRunProps> = ({ rankingId }) => {
  const { t } = useTranslation();

  const [query, setQuery] = useQueryParams({
    latestListMadeVisible: StringParam,
    rankListName: StringParam,
    rankListJobIds: ArrayParam,
    search: StringParam,
    section: StringParam,
    district: StringParam,
  });

  const getTotalItems = useCallback((data: TD_GetRankList) => data?.td_rankList?.rankingItems?.totalItems ?? 0, []);

  const searchFilter = useDebounce(query.search, 1000);

  const transformVariables = (optionsWithControls: any) => {
    const { limit = 10, offset = 0 } = optionsWithControls ?? {};

    const localityFilters = getLocalityFilters(query.section, query.district);
    const itemFilter = {
      ...getSearchFilter(searchFilter),
    };

    if (localityFilters['section']) {
      itemFilter['participants'] = { section: localityFilters['section'] };
    }
    if (localityFilters['district']) {
      itemFilter['participants'] = { district: localityFilters['district'] };
    }

    return {
      id: rankingId,
      itemFilter,
      itemPageArgs: {
        limit,
        skip: offset,
      },
    };
  };

  const {
    data: rankListData,
    loading: loadingRankList,
    controlProps,
  } = useControlledQuery<TD_GetRankList>(TD_GET_RANK_LIST, {
    getTotalItems,
    transformVariables,
    client: meshGatewayClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const rankList = useMemo(() => rankListData?.td_rankList as TD_GetRankList_td_rankList, [rankListData]);

  const runLabel = useMemo(
    () => `${t('run')}: ${t('member date', { date: new Date(rankList?.createdAt) })}`,
    [rankList?.createdAt, t],
  );

  const queryParams = useMemo(() => generateRankListQueryParams(rankList, query), [query, rankList]);

  const [publishRankList, { loading: publishingList }] = useMutation<TD_PublishRankList>(TD_PUBLISH_RANK_LIST, {
    client: meshGatewayClient,
  });

  const [unpublishRankList, { loading: unpublishingList }] = useMutation<TD_UnpublishRankList>(TD_UNPUBLISH_RANK_LIST, {
    client: meshGatewayClient,
  });

  const [archiveRankLists, { loading: archivingLists }] = useMutation<TD_ArchiveRankLists>(TD_ARCHIVE_RANK_LISTS, {
    client: meshGatewayClient,
  });

  const handlePublishRun = useCallback(
    (status: VisibilityStatusEnum) => {
      if (status === VisibilityStatusEnum.PUBLISHED) {
        publishRankList({
          variables: {
            id: rankingId,
          },
        });
      } else {
        unpublishRankList({
          variables: {
            id: rankingId,
          },
        });
      }
    },
    [publishRankList, rankingId, unpublishRankList],
  );

  const handleArchiveRun = useCallback(() => {
    archiveRankLists({
      variables: {
        ids: [rankingId],
      },
    });
    navigate(`${ROUTES.RANKINGS}`);
  }, [archiveRankLists, rankingId]);

  const handleSetFilters = useCallback(
    (filters: Partial<UpdateFilterProps>) => {
      setQuery(filters);
    },
    [setQuery],
  );

  const loading = loadingRankList || publishingList || unpublishingList || archivingLists;

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          { name: t('rankings'), to: `${ROUTES.RANKINGS}` },
          {
            name: query?.rankListName || '',
            to: `${ROUTES.RANKINGS}/group${queryParams}`,
          },
          {
            name: runLabel,
            active: true,
          },
        ]}
      />
      <RankingRunHeader
        rankList={{
          ...rankList,
          rankListName: query.rankListName,
          runLabel: runLabel,
        }}
        handlePublishRun={handlePublishRun}
        handleArchiveRun={handleArchiveRun}
        loading={loading}
      />
      <RankingRunPanel
        rankList={rankList}
        controlProps={controlProps}
        loading={loadingRankList}
        filters={query}
        setFilters={handleSetFilters}
      />
    </PageMaxWidth>
  );
};

export default RankingRun;
